#white-kor path:nth-child(1) {
  stroke-dasharray: 423px;
  stroke-dashoffset: 423px;
  animation: line-anim-kor 3s ease forwards;
}
@media (min-width: 768px) {
  #white-kor path:nth-child(1) {
    stroke-dasharray: 857px;
    stroke-dashoffset: 857px;
    animation: line-anim-kor 3s ease forwards;
  }
}

#white-kor path:nth-child(2) {
  stroke-dasharray: 577px;
  stroke-dashoffset: 577px;
  animation: line-anim-kor 3s ease forwards;
}

@media (min-width: 768px) {
  #white-kor path:nth-child(2) {
    stroke-dasharray: 1171px;
    stroke-dashoffset: 1171px;
    animation: line-anim-kor 3s ease forwards;
  }
}

#logo-white-kor {
  animation: fill-anim-white 0.5s ease forwards 2.7s;
}

@keyframes line-anim-kor {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill-anim-white {
  from {
    fill: transparent;
  }
  to {
    fill: #ffffff;
  }
}
